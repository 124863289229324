import BarChartStackedHorizontal from 'ui/baseComponents/Charts/BarChartStackedHorizontal'
import ReportTitle from '../../components/ReportTitle'
import { TOP_ACTIVITY_COLORS } from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'

const ReportChartActivity = ({ data, total }) => (
  <ReportTitle>
    <BarChartStackedHorizontal
      {...{
        data,
        barColors: TOP_ACTIVITY_COLORS,
        total,
        isAnimationActive: false,
      }}
    />
  </ReportTitle>
)

export default ReportChartActivity

import moment from 'moment'
import {
  HOUR_IN_SECONDS,
  prettyPrintRoundedTimeInHoursAndMinutes,
  secondsToMinutes,
} from 'utilities/time'
import {
  CHANGE_THRESHOLD_DEFAULT,
  REPORT_PERIOD_IN_WEEKS,
  REPORT_VARIANTS,
} from './consts'
import { DATE_FORMAT } from 'ui/consts'
import { toTitleCase } from 'utilities/string'

export const getIsDINVariant = (variant) => variant === REPORT_VARIANTS.din

export const prefixPositiveSign = (value) =>
  value > 0 ? `+${value}` : `${value}`

export const saveWithPatientName = (name) => {
  const trimmed = name.trim()
  const formattedName = trimmed
    ? toTitleCase(trimmed).replace(/ /g, '')
    : document.title

  const tempTitle = document.title
  document.title = formattedName
  window.print()
  document.title = tempTitle
}

export const getChartIntervals = (format = 'hh:mm A') => {
  const timestamps = []
  const start = moment().startOf('day')
  const end = moment().endOf('day')

  while (start <= end) {
    timestamps.push(start.format(format))
    start.add(10, 'minutes')
  }

  return timestamps
}

export const formatInHoursAndMinutes = ({
  hours = 0,
  minutes = 0,
  absolute = false,
}) => {
  if (!hours && !minutes) {
    return '0m'
  }

  let hoursAdjusted = hours
  let minutesAdjusted = minutes

  if (absolute) {
    hoursAdjusted = Math.abs(hours)
    minutesAdjusted = Math.abs(minutes)
  }

  const hoursFormatted = hoursAdjusted ? `${hoursAdjusted}h` : ''
  const minutesFormatted = minutesAdjusted ? `${minutesAdjusted}m` : ''
  const separator = hours && minutes ? ' ' : ''

  return `${hoursFormatted}${separator}${minutesFormatted}`
}

export const getAverageDiff = (average, prevAverage) => {
  const diff = average - prevAverage
  const duration = moment.duration(diff, 'seconds')
  const { hours, minutes } = duration?._data
  return { hours, minutes }
}

export const summedArray = (arr = []) =>
  arr?.reduce((acc, curr) => acc + curr, 0)

export const getRoundedPercentChange = (current, prev) => {
  if (!prev) {
    return 0
  }
  return Math.round(((current - prev) / prev) * 100)
}

export const getReportDates = () => {
  // 4 week periods ending on the Sunday before the current date. If the current date is a Sunday, the period ends on the Sunday before that.
  const recentPeriodEnd = moment().startOf('isoWeek').subtract(1, 'day')
  const recentPeriodStart = recentPeriodEnd
    .clone()
    .subtract(REPORT_PERIOD_IN_WEEKS, 'weeks')
    .add(1, 'day')
  const prevPeriodEnd = recentPeriodStart.clone().subtract(1, 'day')
  const prevPeriodStart = prevPeriodEnd
    .clone()
    .subtract(REPORT_PERIOD_IN_WEEKS, 'weeks')
    .add(1, 'day')
  const nextPeriodStart = recentPeriodEnd
    .clone()
    .add(REPORT_PERIOD_IN_WEEKS, 'weeks')
    .add(1, 'day')

  return {
    prevStartDate: prevPeriodStart.format(DATE_FORMAT),
    prevEndDate: prevPeriodEnd.format(DATE_FORMAT),
    startDate: recentPeriodStart.format(DATE_FORMAT),
    endDate: recentPeriodEnd.format(DATE_FORMAT),
    nextStartDate: nextPeriodStart.format(DATE_FORMAT),
  }
}

export const formatAverage = (metric) =>
  prettyPrintRoundedTimeInHoursAndMinutes(
    metric?.mean?.[0]?.value / HOUR_IN_SECONDS,
  )
export const getSecondsInMinutes = (total) =>
  moment.duration(total, 'seconds').asMinutes()

export const getAveragePercentChange = (current, prev) => {
  const currentTotalMinutes = getSecondsInMinutes(current)
  const prevTotalMinutes = getSecondsInMinutes(prev)
  return getRoundedPercentChange(currentTotalMinutes, prevTotalMinutes)
}

export const formatDiff = (diff) => {
  const { hours, minutes } = diff
  let label = ''
  if (hours < 0 || minutes < 0) {
    label = 'less'
  } else if (hours > 0 || minutes > 0) {
    label = 'more'
  }

  const formattedHoursAndMinutes = formatInHoursAndMinutes({
    hours,
    minutes,
    absolute: true,
  })

  return `${formattedHoursAndMinutes} ${label}`
}

export const isAtOrBeyondThreshold = (percentChange) =>
  percentChange >= CHANGE_THRESHOLD_DEFAULT ||
  percentChange <= -CHANGE_THRESHOLD_DEFAULT

// BAR CHART HELPERS
export const formatBarChartData = (data = {}, label) =>
  data?.xLabels?.map((item, index) => ({
    name: moment(item, 'hh:mm').format('h A'),
    [label]: secondsToMinutes(data?.averaged[index][0].value),
  }))

const getMaxIndex = (values = []) =>
  values?.reduce((maxIndex, currentValue = [], currentIndex, values = []) => {
    if (currentValue[0].value > values[maxIndex]?.[0]?.value) {
      return currentIndex
    }
    return maxIndex
  }, 0)

export const getCombinedMaxValue = (currentData = {}, prevData = {}) => {
  if (!currentData.averaged?.length && !prevData.averaged?.length) {
    return 0
  }

  const currentMax = getMaxIndex(currentData.averaged) || 0
  const prevMax = getMaxIndex(prevData.averaged) || 0
  const currentMaxValue = secondsToMinutes(
    currentData.averaged?.[currentMax]?.[0]?.value || 0,
  )
  const prevMaxValue = secondsToMinutes(
    prevData.averaged?.[prevMax]?.[0]?.value || 0,
  )

  const ceilToNearestPointFive = (value) => Math.ceil(value * 2) / 2
  const ceilCurrentMaxValue = ceilToNearestPointFive(currentMaxValue)
  const ceilPrevMaxValue = ceilToNearestPointFive(prevMaxValue)
  return Math.max(ceilCurrentMaxValue, ceilPrevMaxValue)
}

export const getReportMetricProps = (currentData, prevData, diff) => {
  const formattedAverage = formatAverage(currentData)
  const percentChange = getAveragePercentChange(
    formatAverage(currentData),
    formatAverage(prevData),
  )
  const formattedHoursAndMinutes = formatInHoursAndMinutes(formattedAverage)

  const noPrevData =
    currentData?.numberOfDaysWithData && !prevData?.numberOfDaysWithData

  return {
    value: formattedHoursAndMinutes,
    trend: percentChange,
    trendLabel: diff,
    noPrevData,
    iconVariant: percentChange >= CHANGE_THRESHOLD_DEFAULT ? 'worse' : 'better',
  }
}

// SCATTER CHART HELPERS
const getRoundedTimestampUsingSelectedTimezone = (
  timestamp,
  format,
  selectedTimezone,
) =>
  moment
    .tz(
      Math.round(timestamp / (10 * 60 * 1000)) * (10 * 60 * 1000),
      selectedTimezone,
    )
    .format(format)

export const roundTimestampsToTenMinutes = ({
  item = {},
  format = 'hh:mm A',
  selectedTimezone,
}) => {
  const timestamp = moment.unix(item?.duration?.startTime)

  const roundedTimestamp = getRoundedTimestampUsingSelectedTimezone(
    timestamp,
    format,
    selectedTimezone,
  )

  return roundedTimestamp
}

export const formatBubbleChartData = ({ data = [], selectedTimezone }) => {
  const chartIntervals = getChartIntervals()
  const roundedTimestampData = data?.map((item) =>
    roundTimestampsToTenMinutes({
      item,
      selectedTimezone,
    }),
  )

  const formattedData = chartIntervals.map((timestamp) => ({
    timestamp,
    name: moment(timestamp, 'hh:mm A').format('h A'),
    value: 0,
    index: 0,
  }))

  roundedTimestampData.forEach((timestamp, i) => {
    const index = formattedData.findIndex(
      (item) => item.timestamp === timestamp,
    )
    formattedData[index].value = 1
  })

  return formattedData
}

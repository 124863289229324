import { useGetHeaders } from 'domains/auth/hooks'
import { useTangerineApi } from 'domains/tangerine/hooks'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'

const useActivityOverview = ({ startDate, endDate }) => {
  const patientContext = useContext(PatientContext) || {}
  const getHeaders = useGetHeaders()
  const { config } = window.Rune.Carrot

  const { data, loading, errors } = useTangerineApi({
    url: `https://${config.strivepd.host}/api/v3/activity_overview`,
    method: 'GET',
    params: {
      start_date: startDate,
      end_date: endDate,
      patient_id: patientContext.id,
    },
    axiosOptions: { responseType: 'arraybuffer' },
    getHeaders,
  })

  return {
    data: data?.data || {},
    isLoading: loading,
    hasError: !!(errors && errors.length),
  }
}

export default useActivityOverview

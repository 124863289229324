import { memo } from 'react'
import UpIcon from '@mui/icons-material/ArrowUpward'
import DownIcon from '@mui/icons-material/ArrowDownward'
import { colors } from 'theme/colors'

const ReportMetricTrendIcon = ({ direction = 'up', variant = 'better' }) => {
  let iconProps = {}
  if (variant === 'better') {
    iconProps = {
      color: 'success',
      sx: { backgroundColor: colors.LIME[100], borderRadius: '100%' },
    }
  } else if (variant === 'worse') {
    iconProps = {
      color: 'error',
      sx: { backgroundColor: colors.RED[100], borderRadius: '100%' },
    }
  }

  return direction === 'up' ? (
    <UpIcon
      {...iconProps}
      data-cy={`trend-icon-${direction}-${variant || 'no-color'}`}
    />
  ) : (
    <DownIcon
      {...iconProps}
      data-cy={`trend-icon-${direction}-${variant || 'no-color'}`}
    />
  )
}

export default memo(ReportMetricTrendIcon)

import { gql } from '@apollo/client'

export const GET_PATIENT = gql`
  query getPatient($patientId: ID!, $withDisabled: Boolean) {
    patient(id: $patientId) {
      id
      codeName
      identifiableInfo {
        realName
        firstName
        lastName
        demographicData {
          dateOfBirth
          pdDiagnosisDate
        }
      }
      userInfo {
        timezone
      }
      deviceList(withDisabled: $withDisabled) {
        pageInfo {
          endCursor
        }
        devices {
          id
          deviceShortId
          deviceType {
            id
            displayName
          }
          alias
          kind
          disabled
          channels
          createdAt
        }
      }
      medicationScheduleList {
        pageInfo {
          endCursor
        }
        schedules {
          autolog
          active
          createdAt
          updatedAt
          displayName
          customDetail {
            displayName
          }
          dosage
          daysOfWeek
          id
          scheduleType
          unitQuantity
          localTime
          utcTime
          unit
          classification {
            namespace
            enum
            category
          }
        }
      }
    }
  }
`

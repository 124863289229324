import moment from 'moment'
import { secondsToMinutesCeil, WEEK_IN_SECONDS } from 'utilities/time'
import { formatInHoursAndMinutes, prefixPositiveSign } from '../../helpers'
import {
  ACTIVITY_TABLE_HELPER_DEFAULT,
  ACTIVITY_TABLE_HELPER_FEW_CATEGORIES,
  ACTIVITY_TABLE_HELPER_NO_HR,
  ACTIVITY_TABLE_HELPER_NO_RPE,
  ACTIVITY_TABLE_MAINTAINED,
  ACTIVITY_TABLE_NO_DIFFERENCE,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'
import { Text } from 'ui/baseComponents'
import { colors } from 'theme/colors'

const calculateWeeklyStartTimes = (periodStartTime) =>
  Array.from({ length: 4 }, (_, i) => periodStartTime + WEEK_IN_SECONDS * i)

export const calculateWeeklyActivityDurations = (
  events = [],
  periodStartTime,
) => {
  // Initialize an array with 4 elements, each set to 0, representing the 4 weeks.
  let weeklyDurations = Array(4).fill(0)
  const weeklyStartTimes = calculateWeeklyStartTimes(periodStartTime)
  events.forEach(({ duration: { startTime, endTimeMax } }) => {
    const durationInSeconds = endTimeMax ? endTimeMax - startTime : 0
    const weekIndex = weeklyStartTimes.findIndex(
      (weekStartTime, index) =>
        startTime >= weekStartTime &&
        startTime < (weeklyStartTimes[index + 1] || Infinity),
    )

    // If the event falls within the 4-week period, add its duration to the corresponding week.
    if (weekIndex >= 0 && weekIndex < 4) {
      weeklyDurations[weekIndex] += durationInSeconds
    }
  })

  weeklyDurations = weeklyDurations.map((durationInSeconds) =>
    Math.floor(durationInSeconds / 60),
  )

  return weeklyDurations
}

export const countWeeksAtOrOverTarget = (weeklyDurations = [], target = 150) =>
  weeklyDurations.filter((duration) => duration >= target).length

export const getAveragePerWeek = (totalDurationInMinutes = 0, returnAsNumber) =>
  returnAsNumber
    ? Math.floor(totalDurationInMinutes / 4)
    : `${Math.floor(totalDurationInMinutes / 4)}m`

export const getDurationInSeconds = (startTimestamp, endTimestamp) => {
  const start = moment.unix(startTimestamp)
  const end = endTimestamp ? moment.unix(endTimestamp) : start
  return moment.duration(end.diff(start)).asSeconds()
}

export const formatSecondsToHoursAndMinutes = (seconds) => {
  const duration = moment.duration(seconds, 'seconds')
  const { days, hours, minutes } = duration?._data
  return formatInHoursAndMinutes({ hours: hours + days * 24, minutes })
}

// ====== New functions ====== //

const formatToMinutesOrEmptyText = (duration, emptyText) =>
  secondsToMinutesCeil(duration) ? (
    `${prefixPositiveSign(secondsToMinutesCeil(duration))}m`
  ) : (
    <Text variant="body12" color={colors.COOL[700]}>
      {emptyText}
    </Text>
  )

export const getActivityDurationsByCategory = (
  recentData = [],
  pastData = [],
) =>
  recentData.map((category) => {
    const prevDuration =
      pastData.find(
        (prev) => prev?.tag?.display_name === category.tag.display_name,
      )?.total_duration || 0

    const difference = category.total_duration - prevDuration

    return {
      name: category.tag.display_name,
      recent: secondsToMinutesCeil(category.total_duration),
      previous: secondsToMinutesCeil(prevDuration),
      difference: formatToMinutesOrEmptyText(
        difference,
        ACTIVITY_TABLE_MAINTAINED,
      ),
    }
  })

export const getActivityCategoryChartData = (activityDurationsByCategory) =>
  activityDurationsByCategory.map((category) => ({
    name: category.name,
    recent: category.recent,
    previous: category.previous,
  }))

export const getWeeklyActivityAverages = (recentData = {}, prevData = {}) => {
  const { average_total = 0, high_intensity_total = 0 } =
    recentData?.average_activity_duration || {}
  const {
    average_total: prevAverageTotal = 0,
    high_intensity_total: prevHighIntensityTotal = 0,
  } = prevData?.average_activity_duration || {}
  const durationDiff = average_total - prevAverageTotal
  const highIntensityDiff = high_intensity_total - prevHighIntensityTotal

  return {
    weeklyDurationAvg: secondsToMinutesCeil(average_total),
    weeklyDurationDiff: secondsToMinutesCeil(durationDiff),
    weeklyHighIntensityAvg: secondsToMinutesCeil(high_intensity_total),
    weeklyHighIntensityDiff: secondsToMinutesCeil(highIntensityDiff),
  }
}

export const getFormattedActivityRows = (recentData = {}, prevData = {}) => {
  const recentActivities = recentData.activity_overview
  const prevActivities = prevData.activity_overview
  const formattedRows = []

  recentActivities?.forEach((activity = {}) => {
    const {
      name,
      count = 0,
      total_duration = 0,
      high_intensity_duration = 0,
      average_rpe,
      tags = [],
    } = activity

    const prevActivity = prevActivities?.find(
      (prev) => prev.name === activity.name,
    )
    const durationDifference =
      total_duration - (prevActivity?.total_duration || 0)

    const highIntensityDurationDifference =
      high_intensity_duration - (prevActivity?.high_intensity_duration || 0)

    formattedRows.push({
      name: name,
      duration: secondsToMinutesCeil(total_duration),
      durationDifference: formatToMinutesOrEmptyText(
        durationDifference,
        ACTIVITY_TABLE_NO_DIFFERENCE,
      ),
      logs: count,
      logsPrevious: prevActivity?.count || 0,
      rpeAvg: average_rpe || '–',
      rpeAvgPrevious: prevActivity?.average_rpe || '–',
      highIntensityDuration: secondsToMinutesCeil(high_intensity_duration),
      highIntensityDurationDifference: formatToMinutesOrEmptyText(
        highIntensityDurationDifference,
        ACTIVITY_TABLE_NO_DIFFERENCE,
      ),
      categories: tags.map((tag) => tag.name),
    })
  })

  const sortedByDuration = formattedRows.sort((a, b) => b.duration - a.duration)

  return sortedByDuration
}

export const getActivityTableHelper = (
  recentData = {},
  activityDurationsByCategory = [],
) => {
  const hasTwoOrMoreCategories =
    activityDurationsByCategory.filter((category) => category.recent > 0)
      ?.length > 1
  const hasRpeData = recentData?.activity_overview?.some(
    (activity) => activity.average_rpe,
  )

  if (!recentData?.has_heart_rate_data) {
    return ACTIVITY_TABLE_HELPER_NO_HR
  } else if (!hasTwoOrMoreCategories) {
    return ACTIVITY_TABLE_HELPER_FEW_CATEGORIES
  } else if (!hasRpeData) {
    return ACTIVITY_TABLE_HELPER_NO_RPE
  } else {
    return ACTIVITY_TABLE_HELPER_DEFAULT
  }
}

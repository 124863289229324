import NavLink from '../../NavLink'
import PropTypes from 'prop-types'
import { Breadcrumbs, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'flex',
    flex: '1 1 auto',
    padding: '1rem',
    color: theme.palette.text.primary,
  },
  patientsBasic: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
}))

const PatientBreadcrumbs = ({ currentPageName }) => {
  const classes = useStyles()

  return (
    <Breadcrumbs className={classes.breadcrumbs}>
      <NavLink
        to="/patients"
        className={classes.patientsBasic}
        data-cy="patient-breadcrumbs-patients-list-link"
      >
        Patients
      </NavLink>
      <Typography color="textPrimary" variant="h6">
        {currentPageName}
      </Typography>
    </Breadcrumbs>
  )
}

PatientBreadcrumbs.propTypes = {
  currentPageName: PropTypes.string.isRequired,
}

PatientBreadcrumbs.defaultProps = {}

export default PatientBreadcrumbs

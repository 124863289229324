import * as MUI from '@mui/material'
import { useFlags } from 'domains/launchdarkly/hooks'
import { useContext, useState } from 'react'
import { Alert } from 'ui/baseComponents'
import { PatientContext } from 'ui/contexts'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const ExperimentalIFrame = ({
  widget = '',
  width = '100%',
  height = '700px',
}) => {
  const STYLES = {
    accordion: {
      '&.MuiAccordion-root.Mui-expanded': {
        margin: 0,
      },
      '@media print': {
        display: 'none',
      },
    },
  }

  const { experimentalWebComponentsVisible } = useFlags()
  const { id: patientId } = useContext(PatientContext)

  const [showVPNAlert, setShowVPNAlert] = useState(true)
  const [expanded, setExpanded] = useState(true)

  if (!experimentalWebComponentsVisible || !widget) {
    return null
  }

  return (
    <MUI.Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={STYLES.accordion}
    >
      <MUI.AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {widget}
      </MUI.AccordionSummary>
      <MUI.AccordionDetails>
        <>
          {showVPNAlert && (
            <Alert severity="warning">Please check your VPN.</Alert>
          )}

          <iframe
            {...{
              width,
              height: showVPNAlert ? '0' : height,
              frameBorder: '0',
              src: `https://datascience-${widget}.runelabs.io/?patient_id=${patientId}`,
              allow: 'clipboard-read; clipboard-write',
              title: widget,
              'data-cy': 'experimental-iframe',
              onLoad: () => setShowVPNAlert(false),
            }}
          />
        </>
      </MUI.AccordionDetails>
    </MUI.Accordion>
  )
}

export default ExperimentalIFrame

import { useContext } from 'react'
import { CognitoUserPoolContext } from 'ui/contexts'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import ProtectedRoute from '../ProtectedRoute'
import { getAutomatedUserJwt } from 'utilities/automatedUser'

const ProtectedByLogin = () => {
  const cognitoUserPool = useContext(CognitoUserPoolContext)
  const isAllowed =
    !!getAutomatedUserJwt() || !!cognitoUserPool.getCurrentUser()

  if (!isAllowed) {
    const { pathname, search } = window.location
    const referrerPath = `${pathname}${search}`
    sessionStorage.setItem('referrer', referrerPath)
  }

  return (
    <ProtectedRoute
      isAllowed={isAllowed}
      redirectPath={ROUTE_METADATA.signin.path}
    />
  )
}

ProtectedByLogin.propTypes = {}

ProtectedByLogin.defaultProps = {}

export default ProtectedByLogin

import { useQuery } from '@apollo/client'
import { GET_PATIENT } from 'domains/carrotGraph/queries'

/**
 * Get a Patient by id.
 * @param {Object} options - Configuration options for the hook.
 * @param {string} options.patientId - A string corresponding to the patient's id
 * @param {boolean} options.skip - Flag indicating whether to skip the query or not.
 * @return {{loading, error, patient}} loading is the loading state of the request returned by useQuery. error is the error state of the request, if any, returned by useQuery. patient is an object with the following properties: codeName, deviceList, id, identifiableInfo, userInfo, and medicationScheduleList.
 */
const usePatient = ({ patientId, skip = false }) => {
  const { loading, error, data } = useQuery(GET_PATIENT, {
    variables: { patientId, withDisabled: false },
    fetchPolicy: 'cache-and-network',
    skip,
  })

  const patient = data?.patient

  return { loading, error, patient }
}

export default usePatient

import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { ThemeProvider as MuiV5ThemeProvider } from '@mui/material/styles'
import {
  ThemeProvider as MuiV4ThemeProvider,
  withTheme as muiWithTheme,
} from '@material-ui/core'
import { ConfirmProvider } from 'material-ui-confirm'
import { strivePDThemeMUIV4 } from 'theme/strivePDThemeMUIV4'
import { strivePDTheme } from 'theme'

const MaterialUiTheme = ({ children }) => {
  return (
    <MuiV4ThemeProvider theme={strivePDThemeMUIV4}>
      <MuiV5ThemeProvider theme={strivePDTheme}>{children}</MuiV5ThemeProvider>
    </MuiV4ThemeProvider>
  )
}

const StyledComponentsTheme = ({ children }) => {
  return (
    <StyledComponentsThemeProvider theme={strivePDThemeMUIV4}>
      {children}
    </StyledComponentsThemeProvider>
  )
}

const StyledComponentsThemeWithMui = muiWithTheme(StyledComponentsTheme)

export const WithTheme = ({ children }) => (
  <MaterialUiTheme>
    <StyledComponentsThemeWithMui>
      <ConfirmProvider>{children}</ConfirmProvider>
    </StyledComponentsThemeWithMui>
  </MaterialUiTheme>
)

import { useContext } from 'react'
import { useLocation } from 'react-router-dom'
import PatientContext from 'ui/contexts/PatientContext'
import PageHeader from 'ui/templates/PageHeader'
import './ClinicianPatientHeader.css'
import ClinicianPortalBreadcrumbs from 'ui/components/Breadcrumbs/ClinicianPortalBreadcrumbs'
import { useFlags } from 'domains/launchdarkly/hooks'
import useSummaryModeContext from 'ui/contexts/SummaryModeContext'
import {
  NO_DATA,
  PATIENT_INFO_TITLES,
} from 'ui/clinicianScreens/ClinicianPatientHeader/consts'
import { DATE_FORMAT_HUMAN_SHORT } from 'ui/consts'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { formatDateString } from 'utilities/time'
import { Stack, Text } from 'ui/baseComponents'
import { colors } from 'theme/colors'

const PatientInfoData = ({ children }) => (
  <Text variant="body16">{children}</Text>
)

const PatientInfoTitle = ({ children }) => (
  <Text variant="body14" color={colors.GREY[700]}>
    {children}
  </Text>
)

const ClinicianPatientHeader = ({ getTabs }) => {
  const {
    overviewTabVisible,
    monthlyReportsTabVisibile: monthlyReportsTabVisible,
    striveGptVisible,
    clinicianReportVisible,
    weeklyViewTabVisible,
  } = useFlags()
  const summaryModeContextValue = useSummaryModeContext()
  const { comparisonModeEnabled, setComparisonModeEnabled } =
    summaryModeContextValue || {}
  const { pathname } = useLocation()
  const patient = useContext(PatientContext)
  const { identifiableInfo } = patient || {}
  const { demographicData } = identifiableInfo || {}
  const { dateOfBirth: dob, pdDiagnosisDate } = demographicData || {}

  const splitPathname = pathname.split('/')
  const pagefromPathname = splitPathname.pop()
  const { summary, summaryComparison } = ROUTE_METADATA
  // If the user navigates to the summaryComparison route, we set comparisonModeEnabled to true
  if (pagefromPathname === summaryComparison.path && !comparisonModeEnabled) {
    setComparisonModeEnabled(true)
  }
  if (pagefromPathname === summary.path && comparisonModeEnabled) {
    setComparisonModeEnabled(false)
  }

  const TABS =
    getTabs({
      comparisonModeEnabled,
      overviewTabVisible,
      monthlyReportsTabVisible,
      striveGptVisible,
      clinicianReportVisible,
      weeklyViewTabVisible,
    }) || []

  const tabMatch = TABS.find((tab) => tab.to === pagefromPathname)?.label
  const currentPageName = tabMatch || ''

  const { dateOfBirth, diagnosisDate } = PATIENT_INFO_TITLES
  const patientDateOfBirth = dob
    ? formatDateString({
        dateString: dob,
        format: DATE_FORMAT_HUMAN_SHORT,
      })
    : NO_DATA
  const patientDiagnosisDate = pdDiagnosisDate
    ? formatDateString({
        dateString: pdDiagnosisDate,
        format: DATE_FORMAT_HUMAN_SHORT,
      })
    : NO_DATA

  return (
    <PageHeader
      title={
        <Stack>
          <ClinicianPortalBreadcrumbs
            patient={patient}
            currentPageName={currentPageName}
          />
          <Stack direction="row" spacing={2}>
            <div>
              <PatientInfoTitle>{dateOfBirth}</PatientInfoTitle>
              <PatientInfoData>{patientDateOfBirth}</PatientInfoData>
            </div>
            <div>
              <PatientInfoTitle>{diagnosisDate}</PatientInfoTitle>
              <PatientInfoData>{patientDiagnosisDate}</PatientInfoData>
            </div>
          </Stack>
        </Stack>
      }
    />
  )
}

export default ClinicianPatientHeader

import { element } from 'prop-types'
import { strivePDTheme } from 'theme'
import { Box } from '@mui/material'

const TwoColumnLayout = ({ columnOne, columnTwo }) => {
  const STYLES = {
    grid: {
      display: 'grid',
      gridRowGap: '3rem',
      gridTemplateColumns: '1fr',
      padding: '1rem 1.5rem',
      gridColumnGap: '2.5rem',
      [strivePDTheme.breakpoints.up('md')]: {
        gridColumnGap: '3rem',
        gridTemplateColumns: '1fr 1fr',
      },
      [strivePDTheme.breakpoints.up('lg')]: {
        gridTemplateColumns: '40% 1fr',
      },
      [strivePDTheme.breakpoints.up('xl')]: {
        gridTemplateColumns: '30% 1fr',
      },
    },
  }

  return (
    <Box sx={{ ...STYLES.grid }} data-cy="two-column-layout">
      {columnOne}
      {columnTwo}
    </Box>
  )
}

TwoColumnLayout.propTypes = {
  columnOne: element.isRequired,
  columnTwo: element.isRequired,
}

TwoColumnLayout.defaultProps = {}

export default TwoColumnLayout

import { useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import ClinicianPatientWrapper from 'ui/clinicianScreens/Patient/ClinicianPatientWrapper'
import EditPatient from 'ui/screens/EditPatient'
import Root from 'ui/screens/Root'
import { ROUTES } from './consts'
import {
  ProtectedByAdmin,
  ProtectedByFeatureFlag,
  ProtectedByLogin,
} from './ProtectedRoutes'
import { useTitle } from 'ui/hooks'

const AppRoutes = () => {
  const { pathname } = useLocation()
  useTitle(pathname)
  // Reset scrolling position when path changes
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
  return (
    <Routes>
      <Route element={<ProtectedByLogin />}>
        <Route path="/" element={<Root />}>
          <Route
            index
            element={<Navigate to={ROUTES.patients.path} replace />}
          />

          {/* --------PATIENTS------- */}
          <Route path={ROUTES.patients.path}>
            {/* --------DEFAULT (NO MATCH)------- */}
            <Route path="*" element={<Navigate to={ROUTES.patients.path} />} />

            {/* --------PATIENT LIST------- */}
            <Route index element={ROUTES.patients.element} />

            {/* --------TASK NOTIFICATIONS------- */}
            <Route element={<ProtectedByAdmin redirectPath=".." />}>
              <Route
                path={ROUTES.taskNotifications.path}
                element={ROUTES.taskNotifications.element}
              />
            </Route>

            {/* --------SINGLE PATIENT------- */}
            <Route
              path={ROUTES.patientId.path}
              element={ROUTES.patientId.element}
            >
              {/* --------PATIENT SETTINGS------- */}
              <Route element={<ProtectedByAdmin redirectPath=".." />}>
                <Route
                  path={ROUTES.editPatient.path}
                  // element={ROUTES.editPatient.element}  // doesn't work
                  element={<EditPatient />} // works
                >
                  {/* --------DEFAULT (NO MATCH)------- */}
                  <Route
                    index
                    path="*"
                    element={<Navigate to={ROUTES.details.path} replace />}
                  />

                  {/* --------MATCHED ROUTES------- */}
                  <Route
                    path={ROUTES.details.path}
                    element={ROUTES.details.element}
                  />
                  <Route
                    path={ROUTES.clients.path}
                    element={ROUTES.clients.element}
                  />
                  <Route path={ROUTES.devices.path}>
                    <Route index element={ROUTES.devices.element} />
                    <Route
                      path={ROUTES.deviceId.path}
                      element={ROUTES.deviceId.element}
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                path={ROUTES.reportPatientPDFTest.path}
                element={ROUTES.reportPatientPDFTest.element}
              />
              <Route element={<ClinicianPatientWrapper />}>
                {/* --------DEFAULT (NO MATCH)------- */}
                <Route
                  index
                  path="*"
                  element={<Navigate to={ROUTES.summary.path} replace />}
                />

                {/* --------MATCHED ROUTES------- */}
                <Route
                  path={ROUTES.daily.path}
                  element={ROUTES.daily.element}
                />
                <Route path={ROUTES.log.path} element={ROUTES.log.element} />
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="overviewTabVisible"
                      redirectPath={ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={ROUTES.overview.path}
                    element={ROUTES.overview.element}
                  />
                </Route>
                <Route
                  path={ROUTES.summary.path}
                  element={ROUTES.summary.element}
                />
                <Route
                  path={ROUTES.summaryComparison.path}
                  element={ROUTES.summaryComparison.element}
                />
                <Route
                  path={ROUTES.reportPatient.path}
                  element={ROUTES.reportPatient.element}
                />
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="clinicianReportVisible"
                      redirectPath={ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={ROUTES.reportClinician.path}
                    element={ROUTES.reportClinician.element}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="weeklyViewTabVisible"
                      redirectPath={ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={ROUTES.weeklyView.path}
                    element={ROUTES.weeklyView.element}
                  />
                </Route>
                <Route
                  element={
                    <ProtectedByFeatureFlag
                      flagName="striveGptVisible"
                      redirectPath={ROUTES.summary.path}
                    />
                  }
                >
                  <Route
                    path={ROUTES.questionAnswer.path}
                    element={ROUTES.questionAnswer.element}
                  />
                </Route>
              </Route>
            </Route>
          </Route>

          {/* --------USER SETTINGS------- */}
          <Route path={ROUTES.settings.path} element={ROUTES.settings.element}>
            {/* --------DEFAULT (NO MATCH)------- */}
            <Route
              index
              path="*"
              element={<Navigate to={ROUTES.profile.path} replace />}
            />

            {/* --------MATCHED ROUTES------- */}
            <Route
              path={ROUTES.profile.path}
              element={ROUTES.profile.element}
            />
            <Route
              path={ROUTES.cookiePreferences.path}
              element={ROUTES.cookiePreferences.element}
            />
            <Route
              path={ROUTES.accessTokens.path}
              element={ROUTES.accessTokens.element}
            />
          </Route>

          {/* --------USERS------- */}
          <Route
            element={<ProtectedByAdmin redirectPath={ROUTES.patients.path} />}
          >
            <Route path={ROUTES.users.path} element={ROUTES.users.element} />
          </Route>
        </Route>
      </Route>

      {/* --------SIGN IN------- */}
      <Route path={ROUTES.signin.path} element={ROUTES.signin.element} />
    </Routes>
  )
}

export default AppRoutes

/*  ROUTE_METADATA is defined separately from ROUTES
    to avoid circular dependencies when accessing this data in other files. */
export const ROUTE_METADATA = {
  accessTokens: {
    path: 'access-tokens',
    title: 'Access Tokens',
  },
  clients: {
    path: 'clients',
  },
  cookiePreferences: {
    path: 'cookie-preferences',
    title: 'User Settings',
  },
  daily: {
    path: 'daily',
    title: 'Daily View',
  },
  details: {
    path: 'details',
  },
  deviceId: {
    path: ':deviceId',
  },
  devices: {
    path: 'devices',
  },
  editPatient: {
    path: 'manage',
    title: 'Settings',
  },
  log: {
    path: 'log',
    title: 'Patient Log',
  },
  overview: {
    path: 'overview',
    title: 'Overview',
  },
  patientId: {
    path: ':patientId',
  },
  patients: {
    path: '/patients',
    title: 'All Patients',
  },
  profile: {
    path: 'profile',
    title: 'User Settings',
  },
  questionAnswer: {
    path: 'questionAnswer',
    title: 'StriveGPT',
  },
  reportClinician: {
    path: 'reportClinician',
    title: 'Clinician Report',
  },
  reportPatient: {
    path: 'report',
    title: 'Patient Report',
  },
  reportPatientPDFTest: {
    path: 'reportPatientPDFTest',
    title: 'Test Patient Report PDF',
  },
  settings: {
    path: '/settings',
    title: 'User Settings',
  },
  signin: {
    path: '/signin',
  },
  summary: {
    path: 'summary',
    title: 'Detail View',
  },
  summaryComparison: {
    path: 'summaryComparison',
    title: 'Detail View',
  },
  taskNotifications: {
    path: 'task-notifications',
  },
  users: {
    path: '/users',
    title: 'Team',
  },
  weeklyView: {
    path: 'weeklyView',
    title: 'Weekly View',
  },
}

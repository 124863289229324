import { useContext } from 'react'
import ChartLoading from 'ui/components/ChartLoading'
import ErrorFrame from 'ui/components/ErrorFrame'
import {
  convertUnixTimestampToSpecifiedTimezone,
  SECOND_IN_MILLISECONDS,
} from 'utilities/time'
import { shape, string } from 'prop-types'
import SummaryTableCard from 'ui/components/SummaryTableCard'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { DateRangeContext } from 'ui/contexts/DateRangeContext'
import { PatientContext } from 'ui/contexts'
import { NOTES_LABELS } from './consts'
import useBoundStore from 'domains/zustand/store'

/**
 *
 * @param {object} classes The styles for the various components
 * @returns {JSX.Element} Summary of patient-logged notes for the current time period.
 */
const NotesLog = ({ classes }) => {
  const { id } = useContext(PatientContext)
  const { selectedTimezone } = useContext(DateRangeContext)

  const eventList = useBoundStore((state) => state.eventList)
  const errorEvents = useBoundStore((state) => state.errorEvents)
  const loadingEvents = useBoundStore((state) => state.loadingEvents)

  const { log } = ROUTE_METADATA

  const notesEvents =
    eventList?.filter((event) => event.classification.category === 'note') || []

  const notesLogRender =
    notesEvents &&
    notesEvents.map((event, key) => {
      const timesFormatted = convertUnixTimestampToSpecifiedTimezone({
        timestamp: event.duration.startTime * SECOND_IN_MILLISECONDS,
        timezoneName: selectedTimezone,
        format: 'MMM Do, h:mmA',
      })

      // Filter out not usable notes log items and format the wording
      const CONTENT_INDEX = 1
      const neededPayloadItems = Object.entries(JSON.parse(event.payload)).map(
        (item) => item[CONTENT_INDEX],
      )

      // Return the items along with their times in the table row
      return (
        <TableRow
          key={`${key}-notesLog`}
          type={'tr'}
          className={classes.tableRow}
        >
          <TableCell className={classes.tableCellBold}>
            {timesFormatted}
          </TableCell>
          <TableCell className={classes.tableCell}>
            {neededPayloadItems}
          </TableCell>
        </TableRow>
      )
    })

  if (loadingEvents) {
    return <ChartLoading height={150} />
  }
  if (errorEvents) {
    return <ErrorFrame error="Notes unable to load" height={'175px'} />
  }
  // The population of the table along with formatting
  return (
    <SummaryTableCard
      id={id}
      classes={classes}
      action={{
        label: NOTES_LABELS.link,
        to: `/patients/${id}/${log.path}`,
      }}
      tableTitle={NOTES_LABELS.title}
      dataCyForTable={'NotesLogTable'}
    >
      {notesLogRender}
    </SummaryTableCard>
  )
}

NotesLog.propTypes = {
  classes: shape({
    card: string,
    subTitle: string,
    tableCell: string,
    tableCellBold: string,
    tableCellDetails: string,
    tableRow: string,
  }).isRequired,
}

NotesLog.defaultProps = {
  classes: {},
}

export default NotesLog

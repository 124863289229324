import { Grid } from '@mui/material'
import { useContext } from 'react'
import { PatientContext } from 'ui/contexts'
import { Heatmap } from 'ui/components/Heatmap'
import { GoToButton } from 'ui/components/Button'
import { labels } from './consts'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'
import { LegendDefault } from 'ui/components/Legend'
import { TextWithInformationalIconTooltip } from 'ui/components/TextWithInformationalIconTooltip'
import { BulletedTooltipContent } from 'ui/components/InformationalIconTooltip'
import { stringifyQueryParameters } from 'ui/clinicianScreens/Patient/helpers'
import { string } from 'prop-types'
import { DATE_RANGE } from 'ui/contexts/DateRangeContext'

const PERCENT = 100

const v2DataMatrix = (data) => {
  const { tremor, dyskinesia } = data || {}
  return [
    (tremor.averaged || []).map((value) =>
      value !== null ? (value[0].value / tremor.resolution) * PERCENT : value,
    ),
    (dyskinesia.averaged || []).map((value) =>
      value !== null
        ? (value[0].value / dyskinesia.resolution) * PERCENT
        : value,
    ),
  ]
}

const { daily } = ROUTE_METADATA

const TremorAndDyskinesiaDetails = ({ start, end, data }) => {
  const { id } = useContext(PatientContext)

  // eslint-disable-next-line prefer-destructuring
  const xLabels = data.tremor.xLabels
  const yLabels = ['Tremor timeline', 'Dyskinesia timeline']

  const dataMatrix = v2DataMatrix(data)
  const NUM_DECIMAL_PLACES = 1
  const customData = dataMatrix.map((row) =>
    row.map((datum) =>
      datum === null
        ? 'No data'
        : `${datum.toFixed(NUM_DECIMAL_PLACES)} % of time experiencing symptom`,
    ),
  )

  const HOVER_TEMPLATE = '<extra></extra>%{customdata}<br>%{x}'

  const colorscale = [
    ['0', '#ffffff'],
    ['1', '#1F7198'],
  ]

  const queryString = `?${stringifyQueryParameters(start, end)}`

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <TextWithInformationalIconTooltip
            text="Daily Average"
            title={
              <BulletedTooltipContent
                bulletedContent={labels.TOOLTIP_LIST_FOR_CHART_TITLE}
              />
            }
            wide
          />
        </Grid>
        <Grid item xs>
          <LegendDefault
            minText="Rarely"
            maxText="Often"
            colors={colorscale}
            showNoDataIndicator
          />
        </Grid>
        <Grid item>
          <GoToButton
            label={labels.LINK_TEXT}
            to={`/patients/${id}/${daily.path}${queryString}`}
          />
        </Grid>
      </Grid>
      <Heatmap
        xLabels={xLabels}
        yLabels={yLabels}
        data={dataMatrix}
        customData={customData}
        min={0}
        max={100}
        colors={colorscale}
        hoverTemplate={HOVER_TEMPLATE}
        showNoData
      />
    </>
  )
}

TremorAndDyskinesiaDetails.propTypes = {
  start: string,
  end: string,
  dateRangeKey: string,
}

TremorAndDyskinesiaDetails.defaultProps = {
  start: '',
  end: '',
  dateRangeKey: DATE_RANGE.default.key,
}

export default TremorAndDyskinesiaDetails

import AccessTokens from 'ui/screens/Settings/AccessTokens'
import Clients from 'ui/screens/EditPatient/Clients'
import CookiePreferences from 'ui/screens/Settings/CookiePreferences'
import { Daily } from 'ui/clinicianScreens/Patient/Daily'
import Devices from 'ui/screens/EditPatient/Devices'
import EditDevice from 'ui/screens/EditPatient/Devices/EditDevice'
import EditPatient from 'ui/screens/EditPatient'
import { Log } from 'ui/clinicianScreens/Patient/Log'
import { Overview } from 'ui/clinicianScreens/Patient/Overview'
import Patient from 'ui/screens/Patient'
import PatientDetails from 'ui/screens/EditPatient/PatientDetails/PatientDetails'
import PatientList from 'ui/screens/Patients/PatientList'
import Profile from 'ui/screens/Settings/Profile'
import Settings from 'ui/screens/Settings'
import SignInWrapper from 'ui/screens/SignIn/SignInWrapper'
import { Summary } from 'ui/clinicianScreens/Patient/Summary'
import TaskNotifications from 'ui/screens/Patients/TaskNotifications'
import Users from 'ui/screens/Users'
import { Report } from 'ui/clinicianScreens/Patient/Report'
import ReportPatientPDFTest from 'ui/clinicianScreens/Patient/Report/ReportPatientPDFTest'
import { StriveGPT } from 'ui/clinicianScreens/Patient/StriveGPT'
import { WeeklyView } from 'ui/clinicianScreens/Patient/WeeklyView'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'

export const ROUTES = {
  accessTokens: {
    path: ROUTE_METADATA.accessTokens.path,
    element: <AccessTokens />,
    title: ROUTE_METADATA.accessTokens.title,
  },
  clients: {
    path: ROUTE_METADATA.clients.path,
    element: <Clients />,
  },
  cookiePreferences: {
    path: ROUTE_METADATA.cookiePreferences.path,
    element: <CookiePreferences />,
    title: ROUTE_METADATA.cookiePreferences.title,
  },
  daily: {
    path: ROUTE_METADATA.daily.path,
    element: <Daily />,
    title: ROUTE_METADATA.daily.title,
  },
  details: {
    path: ROUTE_METADATA.details.path,
    element: <PatientDetails />,
  },
  deviceId: {
    path: ROUTE_METADATA.deviceId.path,
    element: <EditDevice />,
  },
  devices: {
    path: ROUTE_METADATA.devices.path,
    element: <Devices />,
  },
  editPatient: {
    path: ROUTE_METADATA.editPatient.path,
    element: EditPatient,
    title: ROUTE_METADATA.editPatient.title,
  },
  log: {
    path: ROUTE_METADATA.log.path,
    element: <Log />,
    title: ROUTE_METADATA.log.title,
  },
  overview: {
    path: ROUTE_METADATA.overview.path,
    element: <Overview />,
    title: ROUTE_METADATA.overview.title,
  },
  patientId: {
    path: ROUTE_METADATA.patientId.path,
    element: <Patient />,
  },
  patients: {
    path: ROUTE_METADATA.patients.path,
    element: <PatientList />,
    title: ROUTE_METADATA.patients.title,
  },
  profile: {
    path: ROUTE_METADATA.profile.path,
    element: <Profile />,
    title: ROUTE_METADATA.profile.title,
  },
  questionAnswer: {
    path: ROUTE_METADATA.questionAnswer.path,
    element: <StriveGPT />,
    title: ROUTE_METADATA.questionAnswer.title,
  },
  reportClinician: {
    path: ROUTE_METADATA.reportClinician.path,
    element: <Report isClinicianReport />,
    title: ROUTE_METADATA.reportClinician.title,
  },
  reportPatient: {
    path: ROUTE_METADATA.reportPatient.path,
    element: <Report />,
    title: ROUTE_METADATA.reportPatient.title,
  },
  reportPatientPDFTest: {
    path: ROUTE_METADATA.reportPatientPDFTest.path,
    element: <ReportPatientPDFTest />,
    title: ROUTE_METADATA.reportPatientPDFTest.title,
  },
  settings: {
    path: ROUTE_METADATA.settings.path,
    element: <Settings />,
    title: ROUTE_METADATA.settings.title,
  },
  signin: {
    path: ROUTE_METADATA.signin.path,
    element: <SignInWrapper />,
  },
  summary: {
    path: ROUTE_METADATA.summary.path,
    element: <Summary />,
    title: ROUTE_METADATA.summary.title,
  },
  summaryComparison: {
    path: ROUTE_METADATA.summaryComparison.path,
    element: <Summary />,
    title: ROUTE_METADATA.summaryComparison.title,
  },
  taskNotifications: {
    path: ROUTE_METADATA.taskNotifications.path,
    element: <TaskNotifications />,
  },
  users: {
    path: ROUTE_METADATA.users.path,
    element: <Users />,
    title: ROUTE_METADATA.users.title,
  },
  weeklyView: {
    path: ROUTE_METADATA.weeklyView.path,
    element: <WeeklyView />,
    title: ROUTE_METADATA.weeklyView.title,
  },
}

import { useEffect } from 'react'
import { ROUTE_METADATA } from 'app/AppRoutes/RouteMetadata'

/**
 * Update the document title based on route
 * @param {string} pathname, the page title
 * @return {Object} includes loading, data, error, and fetchMore from graphQL query
 */
const useTitle = (pathname) => {
  const RUNE_LABS_TITLE = 'Rune Labs | Research and Therapy Development'

  const splitPathname = pathname.split('/')
  const pagefromPathname = splitPathname.pop()
  const routeValues = Object.values(ROUTE_METADATA)
  const titleMatch = routeValues.find((route) => {
    const path = route?.path.replace('/', '')
    return path === pagefromPathname
  })?.title

  const prependPrefix = titleMatch ? `${titleMatch} - ` : ''
  const runeTitle = `${prependPrefix}${RUNE_LABS_TITLE}`

  useEffect(() => {
    document.title = runeTitle
  }, [runeTitle])
}

export default useTitle

import PropTypes from 'prop-types'
import * as MUI from '@mui/material'
import { colors } from 'theme/colors'
import { Text } from 'ui/baseComponents/Text'

const STYLES = {
  checkboxHidden: {
    visibility: 'hidden',
    display: 'none',
  },
  dayLetter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2px',
    cursor: 'pointer',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
  selected: {
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: '0.5rem',
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY[1000],
    color: colors.WHITE,
    cursor: 'pointer',
  },
  notSelected: {
    height: '1.5rem',
    width: '1.5rem',
    marginLeft: '0.5rem',
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: colors.GREY[400],
    color: colors.GREY[900],
    cursor: 'pointer',
  },
}

const DaySelect = ({ setDay, selectedDayArray }) => {
  const FIRST_LETTER_POSITION = 0
  const daysOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ]

  const daysOfWeekValues = [6, 0, 1, 2, 3, 4, 5]

  return (
    <div>
      {daysOfWeekValues.map((dayNumber, i) => (
        <MUI.Box
          onClick={() => setDay(dayNumber)}
          sx={
            selectedDayArray.includes(dayNumber)
              ? STYLES.selected
              : STYLES.notSelected
          }
          key={dayNumber}
        >
          <Text sx={STYLES.dayLetter} component="p" variant="body14B">
            {daysOfWeek[i][FIRST_LETTER_POSITION]}
          </Text>
          <MUI.Checkbox
            checked={selectedDayArray.includes(dayNumber)}
            sx={STYLES.checkboxHidden}
            name={daysOfWeek[i]}
            disableRipple
          />
        </MUI.Box>
      ))}
    </div>
  )
}

DaySelect.propTypes = {
  setDay: PropTypes.func.isRequired,
  selectedDayArray: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default DaySelect

/* eslint-disable no-constant-condition */
import { memo } from 'react'
import { Stack, Table, Text } from 'ui/baseComponents'
import { getFormattedActivityRows } from 'ui/clinicianScreens/Patient/Report/sections/Activity/helpers'
import {
  ACTIVITIES_TABLE_HEADINGS,
  ACTIVITY_CATEGORY_ICONS_MAP,
  ACTIVITY_COLORS,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'

const ReportTableActivities = ({ recentData = [], prevData = [] }) => {
  const STYLES = {
    diffCellText: {
      minWidth: '2rem',
      display: 'inline-block',
    },
    activityDot: {
      display: 'inline-block',
      width: '0.75rem',
      height: '0.75rem',
      borderRadius: '50%',
      marginRight: '0.3rem',
      backgroundColor: ACTIVITY_COLORS.lowIntensityHR,
    },
    activityDotHighIntensity: {
      backgroundColor: ACTIVITY_COLORS.highIntensityHR,
    },
  }
  const formattedRows = getFormattedActivityRows(recentData, prevData)

  const activityHeadings = [
    {
      key: 'heading-activity',
      value: ACTIVITIES_TABLE_HEADINGS.activity,
    },
    {
      key: 'heading-total-time',
      value: ACTIVITIES_TABLE_HEADINGS.total,
    },
    {
      key: 'heading-logs-previous',
      value: ACTIVITIES_TABLE_HEADINGS.logs,
    },
    {
      key: 'heading-rpe-avg-previous',
      value: ACTIVITIES_TABLE_HEADINGS.rpeAvg,
    },
    {
      key: 'heading-time-in-zone 3',
      value: ACTIVITIES_TABLE_HEADINGS.highIntensityDuration,
    },
    {
      key: 'heading-category-icons',
      value: ACTIVITIES_TABLE_HEADINGS.category,
    },
  ]

  const activityRows = formattedRows.map((activity) => [
    {
      key: 'cell-activity',
      value: (
        <Text variant="body16B">
          <span
            data-cy={`activity-dot-${
              activity.highIntensityDuration
                ? 'high-intensity'
                : 'low-intensity'
            }`}
            style={{
              ...STYLES.activityDot,
              ...(activity.highIntensityDuration
                ? STYLES.activityDotHighIntensity
                : {}),
            }}
          />
          {activity.name}
        </Text>
      ),
      sx: { maxWidth: '160px' },
    },
    {
      key: 'cell-total-time',
      value: (
        <Stack direction="row">
          <Text variant="body16B" sx={STYLES.diffCellText}>
            {activity.duration}m
          </Text>
          <Text variant="body16">{activity.durationDifference}</Text>
        </Stack>
      ),
    },
    {
      key: 'cell-logs',
      value: (
        <Text variant="body16B">
          {activity.logs} |{' '}
          <Text variant="body16">{activity.logsPrevious}</Text>
        </Text>
      ),
    },
    {
      key: 'cell-rpe-avg',
      value: (
        <Text variant="body16B">
          {activity.rpeAvg} |{' '}
          <Text variant="body16">{activity.rpeAvgPrevious}</Text>
        </Text>
      ),
    },
    {
      key: 'cell-time-in-zone 3',
      value: (
        <Stack direction="row">
          <Text variant="body16B" sx={STYLES.diffCellText}>
            {activity.highIntensityDuration}m
          </Text>
          <Text variant="body16">
            {activity.highIntensityDurationDifference}
          </Text>
        </Stack>
      ),
    },
    {
      key: 'cell-category-icons',
      value: (
        <Stack direction="row" spacing={0} gap="2px">
          {activity.categories.map((category) => (
            <img
              src={ACTIVITY_CATEGORY_ICONS_MAP.get(category)}
              alt={category}
              width="20"
              key={`activity-category-icon-${category}`}
              data-cy={`activity-category-icon-${category}`}
            />
          ))}
        </Stack>
      ),
    },
  ])

  return (
    <Table
      headings={activityHeadings}
      rows={activityRows}
      size="small"
      data-cy="table-activities"
    />
  )
}

export default memo(ReportTableActivities)

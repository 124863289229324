/* eslint-disable no-constant-condition */
import { Table, Text } from 'ui/baseComponents'
import { ACTIVITY_CATEGORIES_TABLE_HEADINGS } from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'

const ReportTableActivityCategories = ({ activityDurationsByCategory }) => {
  const STYLES = {
    diffCellText: {
      minWidth: '3.5rem',
      paddingRight: '0.5rem',
      display: 'inline-block',
    },
  }

  const activityCategoryHeadings = [
    {
      key: 'heading-category',
      value: ACTIVITY_CATEGORIES_TABLE_HEADINGS.category,
    },
    {
      key: 'heading-total-time',
      value: ACTIVITY_CATEGORIES_TABLE_HEADINGS.total,
    },
  ]
  const activityCategoryRows = activityDurationsByCategory.map((category) => {
    const noCategoryData = !category.recent
    return [
      {
        key: 'cell-category',
        value: <Text variant="body16B">{category.name}</Text>,
      },
      noCategoryData
        ? {
            key: 'cell-total-time',
            value: <Text variant="body16B">–</Text>,
          }
        : {
            key: 'cell-total-time',
            value: (
              <div>
                <Text variant="body16B" sx={STYLES.diffCellText}>
                  {category.recent}m
                </Text>
                <Text variant="body16">{category.difference}</Text>
              </div>
            ),
          },
    ]
  })

  return (
    <Table
      headings={activityCategoryHeadings}
      rows={activityCategoryRows}
      size="small"
      data-cy="table-activity-categories"
    />
  )
}

export default ReportTableActivityCategories

import React from 'react'
import PatientBreadcrumbs from 'ui/components/Breadcrumbs/PatientBreadcrumbs'
import CreateTask from '../CreateTask'
import { Box } from '@material-ui/core'

/**
 * The header for TaskNotifications.
 *
 * @returns {JSX.Element} Task Notifications Header
 */
export const TaskNotificationsHeader = () => (
  <Box display="flex" pl={2}>
    <PatientBreadcrumbs currentPageName="Task Notifications" />
    <CreateTask />
  </Box>
)

export default TaskNotificationsHeader

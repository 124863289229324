import { memo } from 'react'
import { colors } from 'theme/colors'
import { Stack } from 'ui/baseComponents/Stack'

const ReportTitle = ({ children, sx = {}, ...props }) => {
  const STYLES = {
    heading: {
      padding: '0.5rem 1.5rem',
      background: colors.COOL[100],
      ...sx,
    },
  }
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={STYLES.heading}
      {...props}
    >
      {children}
    </Stack>
  )
}

export default memo(ReportTitle)

/* eslint-disable no-constant-condition */
import { useContext, useEffect, useMemo, useState } from 'react'
import { Text } from 'ui/baseComponents/Text'
import ReportSection from '../../components/ReportSection'
import {
  DATA_ATTRIBUTES,
  NO_DATA_EMPTY_STATE,
  SECTION_TITLES,
} from 'ui/clinicianScreens/Patient/Report/consts'
import usePatientEvents from 'ui/hooks/usePatientEvents'
import { reFetchEventList } from 'ui/screens/Patient/queries'
import { PatientContext } from 'ui/contexts'
import { Table } from 'ui/baseComponents/Table'
import EditableLLMContent from '../../components/EditableLLMContent'
import { colors } from 'theme/colors'
import ReportChartActivity from './ReportChartActivity'
import { ReportChartActivityGoal } from './ReportChartActivityGoal'
import { Stack } from 'ui/baseComponents/Stack'
import { formatSecondsToHoursAndMinutes, getDurationInSeconds } from './helpers'
import { summedArray } from '../../helpers'
import { getEventName } from 'ui/clinicianScreens/Patient/helpers'
import {
  HELPER_ACTIVITY,
  HELPER_ACTIVITY_EMPTY_STATE,
  TOP_ACTIVITY_COLORS,
} from 'ui/clinicianScreens/Patient/Report/sections/Activity/consts'

const ReportSectionActivity = ({
  onLoadStart = () => {},
  onLoadEnd = () => {},
  hiddenSections,
  llmKey,
  llmSummary,
  onUpdate = () => {},
  addToUnsavedChanges = () => {},
  removeFromUnsavedChanges = () => {},
  startTime,
  endTime,
  prevStartTime,
  prevEndTime,
  addToHasDataSet,
  removeFromHasDataSet,
  isClinicianReport,
  hasDataSet,
}) => {
  const STYLES = {
    tableContent: {
      padding: '0.5rem 1.5rem',
      margin: 0,
    },
    table: {
      '& .activity-name:before': {
        content: '""',
        display: 'inline-block',
        width: '16px',
        height: '16px',
        borderRadius: '50%',
        backgroundColor: colors.COOL[500],
        marginRight: '0.5rem',
        verticalAlign: 'middle',
      },
      '& tr:nth-of-type(1) .activity-name:before': {
        backgroundColor: TOP_ACTIVITY_COLORS[0],
      },
      '& tr:nth-of-type(2) .activity-name:before': {
        backgroundColor: TOP_ACTIVITY_COLORS[1],
      },
      '& tr:nth-of-type(3) .activity-name:before': {
        backgroundColor: TOP_ACTIVITY_COLORS[2],
      },
      '& tr:nth-of-type(4) .activity-name:before': {
        backgroundColor: TOP_ACTIVITY_COLORS[3],
      },
      '& tr:nth-of-type(5) .activity-name:before': {
        backgroundColor: TOP_ACTIVITY_COLORS[4],
      },
    },
  }

  const title = SECTION_TITLES.ACTIVITY
  const { id: patientId } = useContext(PatientContext)
  const { activity: activityDataAttributes } = DATA_ATTRIBUTES
  const { noActivityDataAttribute } = activityDataAttributes
  const [attributesForPlaywrightScript, setAttributesForPlaywrightScript] =
    useState([noActivityDataAttribute.playwrightScriptSelector])

  const { dataEvents, loadingEvents, fetchMore } = usePatientEvents(
    patientId,
    startTime,
    endTime,
    { namespace: 'patient', category: 'activity', enum: '*' },
  )

  const {
    dataEvents: prevDataEvents,
    loadingEvents: prevLoadingEvents,
    fetchMore: prevFetchMore,
  } = usePatientEvents(patientId, prevStartTime, prevEndTime, {
    namespace: 'patient',
    category: 'activity',
    enum: '*',
  })

  const currentData = useMemo(
    () => dataEvents?.patient?.eventList?.events || [],
    [dataEvents],
  )

  const prevData = useMemo(
    () => prevDataEvents?.patient?.eventList?.events || [],
    [prevDataEvents],
  )

  const prevCounts = prevData.reduce((acc, curr) => {
    const name = getEventName(curr)
    const { startTime, endTimeMax } = curr.duration || {}
    const durationInSeconds = getDurationInSeconds(startTime, endTimeMax)

    if (acc[name]) {
      acc[name].count += 1
      acc[name].duration += durationInSeconds
    } else {
      acc[name] = {
        count: 1,
        duration: durationInSeconds,
      }
    }
    return acc
  }, {})

  const hasData = currentData?.length > 0

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = dataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(fetchMore, endCursor)
    }
  }, [dataEvents, fetchMore])

  useEffect(() => {
    // We need there to be data returned to then get the endCursor to try for other pages worth of data
    const { endCursor } = prevDataEvents?.patient?.eventList?.pageInfo || {}
    if (endCursor) {
      reFetchEventList(prevFetchMore, endCursor)
    }
  }, [prevDataEvents, prevFetchMore])

  const loading = loadingEvents || prevLoadingEvents

  useEffect(() => {
    if (loading) {
      onLoadStart(title)
    } else {
      onLoadEnd(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  useEffect(() => {
    if (dataEvents) {
      hasData ? addToHasDataSet(title) : removeFromHasDataSet(title)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataEvents, hasData])

  const currentDurationsInSeconds = currentData?.map((event) => {
    const { startTime, endTimeMax } = event.duration || {}
    return getDurationInSeconds(startTime, endTimeMax)
  })

  const activityHeadings = [
    {
      key: 'activity',
      value: 'Activity',
    },
    {
      key: 'total time',
      value: 'Total Time | Previous Period',
    },
    {
      key: 'logs',
      value: 'Logs | Previous Period',
    },
    // {
    //   key: 'avg heart rate',
    //   value: 'Avg. HR* | Previous Period',
    // },
  ]

  const activityRowsFormatted = currentData.reduce((acc, curr) => {
    const eventName = getEventName(curr)
    const { startTime, endTimeMax } = curr.duration || {}
    const durationInSeconds = getDurationInSeconds(startTime, endTimeMax)

    if (!acc[eventName]) {
      acc[eventName] = {
        currentDuration: 0,
        prevDuration: prevCounts[eventName]?.duration || 0,
        currentCount: 0,
        prevCount: prevCounts[eventName]?.count || 0,
      }
    }

    acc[eventName].currentDuration += durationInSeconds
    acc[eventName].currentCount += 1

    return acc
  }, {})

  const sortedActivities = Object.entries(activityRowsFormatted).sort(
    (a, b) => {
      const [, dataA] = a
      const [, dataB] = b
      // Example sorting criterion, adjust as necessary
      return dataB.currentDuration - dataA.currentDuration
    },
  )

  const activityRowsSorted = sortedActivities.map(([name, data]) => {
    const currentFormatted = formatSecondsToHoursAndMinutes(
      data.currentDuration,
    )
    const prevFormatted = formatSecondsToHoursAndMinutes(data.prevDuration)

    return [
      {
        key: 'cell-activity',
        value: (
          <Text variant="body16B" className="activity-name">
            {name}
          </Text>
        ),
      },
      {
        key: 'cell-duration',
        value: (
          <>
            <Text variant="body16B">{currentFormatted} | </Text>
            <Text variant="body16">{prevFormatted}</Text>
          </>
        ),
      },
      {
        key: 'cell-logs',
        value: (
          <>
            <Text variant="body16B">{data.currentCount} | </Text>
            <Text variant="body16">{data.prevCount}</Text>
          </>
        ),
      },
      // TODO: Add heart rate data from GQL endpoint
      // {
      //   key: 'cell-heart-rate',
      //   value: (
      //     <>
      //       <Text variant="body16B">120 | </Text>
      //       <Text variant="body16">90</Text>
      //     </>
      //   ),
      // },
    ]
  })

  const topActivities = sortedActivities
    .slice(0, 5)
    .reduce((acc, [, data], index) => {
      acc[index] = data.currentDuration
      return acc
    }, {})
  const restOfActivities = sortedActivities
    .slice(5)
    .reduce((acc, [, data]) => acc + data.currentDuration, 0)
  const chartData = [
    {
      name: 'Activities',
      ...topActivities,
      restOfActivities,
    },
  ]

  const totalActivityTime = summedArray(currentDurationsInSeconds)

  const updateAttributesForPlaywrightScript = (newAttributes) => {
    // If we're getting new attributes, it means we've got data, so the script no longer needs to wait for the no data attribute
    const filteredAttributes = attributesForPlaywrightScript.filter(
      (attribute) =>
        attribute !== noActivityDataAttribute.playwrightScriptSelector,
    )

    setAttributesForPlaywrightScript(() => [
      ...filteredAttributes,
      ...newAttributes,
    ])
  }

  return (
    <ReportSection
      {...{
        title,
        subtitle: 'Data recorded by Apple Health and activity logs',
        hidden:
          hiddenSections.has(title) ||
          (isClinicianReport && !hasDataSet.has(title)),
        ...{
          'data-expected-activity-attributes': attributesForPlaywrightScript,
        },
      }}
    >
      {hasData && (
        <ReportChartActivityGoal
          {...{
            currentData,
            prevData,
            startTime,
            prevStartTime,
            updateAttributesForPlaywrightScript,
          }}
        />
      )}

      {!hasData && !llmSummary && (
        // data-no-activity-data used by script for automated reports
        <Text variant="body16" component="p" data-no-activity-data>
          {NO_DATA_EMPTY_STATE}
        </Text>
      )}

      {llmSummary && (
        <EditableLLMContent
          {...{
            llmKey,
            llmSummary,
            onUpdate,
            addToUnsavedChanges,
            removeFromUnsavedChanges,
            ...(!hasData && {
              [`data-${noActivityDataAttribute.carrotWebSuffix}`]: true,
            }),
          }}
        >
          {llmSummary}
        </EditableLLMContent>
      )}
      {hasData ? (
        <Stack>
          <Text variant="caps12" component="h3">
            Total time distributed in activities logged during the recent period
          </Text>
          {totalActivityTime > 0 && (
            <ReportChartActivity
              data={chartData}
              total={currentDurationsInSeconds}
            />
          )}
          <div style={STYLES.tableContent}>
            <Table
              headings={activityHeadings}
              rows={activityRowsSorted}
              tableCellProps={{ size: 'small' }}
              sx={STYLES.table}
            />
          </div>
          {!isClinicianReport && HELPER_ACTIVITY}
        </Stack>
      ) : (
        HELPER_ACTIVITY_EMPTY_STATE
      )}
    </ReportSection>
  )
}

export default ReportSectionActivity

import { Link } from 'ui/baseComponents/Link'
import { Text } from 'ui/baseComponents/Text'
import { colors } from 'theme/colors'

import activityIconAerobic from 'assets/img/activity-aerobic.svg'
import activityIconBalance from 'assets/img/activity-balance.svg'
import activityIconFlexibility from 'assets/img/activity-flexibility.svg'
import activityIconStrength from 'assets/img/activity-strength.svg'
import { VIDEO_LINKS } from 'ui/clinicianScreens/Patient/Report/consts'
import ReportTitle from 'ui/clinicianScreens/Patient/Report/components/ReportTitle'

export const TOP_ACTIVITY_COLORS = [
  colors.GREEN[500],
  colors.PINK[700],
  colors.BONDI[300],
  colors.ORANGE[400],
  colors.BLUEBERRY[400],
]

export const ACTIVITY_COLORS = {
  time: colors.PRIMARY[1000],
  highIntensityHR: colors.ORANGE[400],
  lowIntensityHR: colors.BLUEBERRY[400],
}

export const ACTIVITY_CATEGORIES_ENUMS = {
  Aerobic: 'activity.aerobic',
  Balance: 'activity.balance',
  Flexibility: 'activity.flexibility',
  Strength: 'activity.strength-training',
}

export const ACTIVITY_CATEGORY_ICONS_MAP = new Map([
  [ACTIVITY_CATEGORIES_ENUMS.Aerobic, activityIconAerobic],
  [ACTIVITY_CATEGORIES_ENUMS.Balance, activityIconBalance],
  [ACTIVITY_CATEGORIES_ENUMS.Flexibility, activityIconFlexibility],
  [ACTIVITY_CATEGORIES_ENUMS.Strength, activityIconStrength],
])

export const ACTIVITIES_TABLE_HEADINGS = {
  activity: 'Activity',
  total: 'Total Time',
  logs: 'Logs | Prev',
  rpeAvg: 'RPE Avg | Prev',
  highIntensityDuration: 'Time in zone 3+',
  category: 'Category',
}

export const ACTIVITY_CATEGORIES_TABLE_HEADINGS = {
  category: 'Category',
  total: 'Total Time',
}

export const ACTIVITY_TABLE_HELPER_DEFAULT = (
  <Text variant="body14" component="p" data-cy="activity-table-helper-default">
    Physical activity plays a key role in managing PD by improving motor
    function, reducing stiffness, and enhancing mobility. Regular movement helps
    to maintain balance, coordination, and flexibility, which are often impacted
    by the condition. Physical activity has also been shown to support mental
    well-being, boost mood, and may slow the progression of symptoms over time.
    To learn more about the importance of activity,{' '}
    <Link href={VIDEO_LINKS.importanceOfActivity} external underline light>
      Watch our video
    </Link>
    .
  </Text>
)

export const ACTIVITY_TABLE_HELPER_NO_HR = (
  <ReportTitle>
    <Text variant="body14" component="p" data-cy="activity-table-helper-no-hr">
      <Text variant="body14B">
        Wearing the Apple Watch tracks your exercise and heart rate
      </Text>{' '}
      (HR) in real-time, providing your StrivePD app with detailed HR zone
      metrics. There are five HR zones, from low to vigorous intensity, with 90
      minutes/week in Zone 3 or higher recommended for optimal fitness.
      Monitoring your progress provides valuable insights and helps you optimize
      your workouts for better results.{' '}
      <Link href={VIDEO_LINKS.hrZones} external underline light>
        Watch our video
      </Link>{' '}
      to learn more.
    </Text>
  </ReportTitle>
)

export const ACTIVITY_TABLE_HELPER_FEW_CATEGORIES = (
  <ReportTitle>
    <Text
      variant="body14"
      component="p"
      data-cy="activity-table-helper-few-categories"
    >
      <Text variant="body14B">Varying exercise types</Text> (aerobic, strength,
      flexibility, and balance training) is crucial for people with PD. Engaging
      in diverse activities can improve different areas, such as mobility,
      stiffness, and fall risk. Click on a category to learn more:{' '}
      <Link href={VIDEO_LINKS.aerobic} external underline light>
        aerobic
      </Link>
      ,{' '}
      <Link href={VIDEO_LINKS.strength} external underline light>
        strength
      </Link>
      ,{' '}
      <Link href={VIDEO_LINKS.flexibility} external underline light>
        flexibility
      </Link>
      ,{' '}
      <Link href={VIDEO_LINKS.balance} external underline light>
        balance
      </Link>
      .
    </Text>
  </ReportTitle>
)

export const ACTIVITY_TABLE_HELPER_NO_RPE = (
  <ReportTitle>
    <Text variant="body14" component="p" data-cy="activity-table-helper-no-rpe">
      <Text variant="body14B">
        Add your Rate of Perceived Exertion (RPE) on the StrivePD app.
      </Text>{' '}
      RPE reflects how challenging a workout feels without depending solely on
      heart rate, which may vary in individuals taking certain medications or
      those who experience autonomic dysfunction with their PD. Aim for 90
      minutes or more weekly at an RPE of 13 or higher.{' '}
      <Link href={VIDEO_LINKS.rpe} external underline light>
        Watch our video
      </Link>{' '}
      on HR Zones and how to use the RPE scale.
    </Text>
  </ReportTitle>
)

export const ACTIVITY_TABLE_NO_DIFFERENCE = 'No difference'
export const ACTIVITY_TABLE_MAINTAINED = 'Maintained'

export const HELPER_ACTIVITY_TIME_IN_CATEGORY = (
  <Text
    variant="body14"
    component="p"
    data-cy="activity-helper-time-in-category"
  >
    Time spent in each category during the recent period, compared to the
    previous period.
  </Text>
)

export const HELPER_ACTIVITY = (
  <Text variant="body14" component="p" data-cy="activity-helper">
    A consistent exercise program is a powerful tool for managing PD. It will
    help you build strength, improve your mobility, and put your body and mind
    in a better position to face the challenges of PD. To learn more about the
    importance of activity,{' '}
    <Link href={VIDEO_LINKS.importanceOfActivity} external underline light>
      watch our video
    </Link>
    .
  </Text>
)

export const HELPER_ACTIVITY_EMPTY_STATE = (
  <>
    <Text variant="body14" component="p" data-cy="activity-helper-no-data">
      A consistent exercise program is a powerful tool for managing PD. It will
      help you build strength, improve your mobility, and put your body and mind
      in a better position to face the challenges of PD. Use the StrivePD app to
      log your activity and track your progress. For additional support, watch
      our videos on{' '}
      <Link href={VIDEO_LINKS.howToLogActivity} external underline light>
        how to log
      </Link>{' '}
      or on the{' '}
      <Link href={VIDEO_LINKS.importanceOfActivity} external underline light>
        importance of activity
      </Link>
      .
    </Text>
  </>
)
